// @flow

import PreviousPuzzles from '../components/PreviousPuzzles';
import GTHPuzzle from '../components/Puzzle/GTHPuzzle';
import Setting from '../components/Setting';
import Stats from '../components/Stats';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion } from './game_settings';
import { answers } from '../puzzles/gth_puzzles';
import type { TGTHAnswer } from '../puzzles/gth_puzzles';
import { BaseSettings } from './BaseSettings';

export class GTHSettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        guess_the_cover: string,
        original_images: string,
        previous_games: string,
        puzzle: string,
        reset: string,
        setting: string,
        stats: string,
        test_affiliate: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId/:puzzleType',
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        original_images: '/ogi/:puzzleId',
        setting: '/setting',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTHPuzzle />} path="/" />
                <Route path={GTHSettings.PATHS.puzzle} element={<GTHPuzzle />} />
                <Route path={GTHSettings.PATHS.stats} element={<Stats />} />
                <Route path={GTHSettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route path={GTHSettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route path={GTHSettings.PATHS.setting} element={<Setting />} />
                <Route path={GTHSettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    puzzle_start_date: Date = new Date('9/25/2024');

    website_url: string = 'GuessThe.House';
    title: string = 'Guess The House';
    website_title: string = 'Guess The House';
    parent_abbr: string = 'GTH'; // used for submitlogin

    max_guesses: number = 6;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTH;
    clipboard_tag: string = '#GuessTheHouse';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '🏠';
    guess_item: string = 'Home';
    tolerance_percentage: number = 5;

    progress_ranks: { [string]: string } = {
        '0': 'Home Hobbyist',
        '5': 'Building Beginner',
        '10': 'Dwelling Decoder',
        '20': 'Architecture Apprentice',
        '40': 'Residence Recognizer',
        '80': 'Estate Explorer',
        '150': 'Structure Scholar',
        '200': 'Domestic Detective',
        '250': 'Habitat Hero',
        '300': 'Architectural Aficionado',
        '350': 'Sanctuary Specialist',
        '400': 'Master of Mansions',
        '430': 'Elite Estate Analyst',
        '470': 'Champion of Chateaus',
        '500': 'Prodigious Property Prognosticator',
        '530': 'Masterful Mansion Mind',
        '560': 'Superior Shelter Strategist',
        '600': 'Grandmaster of Grounds',
        '630': 'Supreme Structure Sage',
        '700': 'Sovereign of Spaces',
        '750': 'Grand Guru of the Gables',
        '800': 'Ultimate Urbanite',
        '900': 'Eternal Estate Expert',
        '1000': 'Omni-Occupant Oracle',
        '1100': 'Galactic Guru of Dwellings',
        '1300': 'Legendary Lodging Luminary',
    };

    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTHAnswer, settings: GameSettings): string {
        const HINT_1 = 'City, State: ';
        const HINT_2 = 'Sold On: ';
        const HINT_3 = 'Lot Size: ';
        const HINT_4 = 'Number of Bed, Bath: ';
        const HINT_5 = 'Year Built: ';
        const HINT_6 = 'Square Footage: ';
        switch (imageIndex) {
            case 1:
                return `${HINT_1} ${puzzle.city_state}`;
            case 2:
                return `${HINT_2} ${puzzle.sold_on}`;
            case 3:
                return `${HINT_3} ${puzzle.lot}`;
            case 4:
                return `${HINT_4} ${puzzle.bed_bath}`;
            case 5:
                return `${HINT_5} ${puzzle.year_built}`;
            case 6:
                return `${HINT_6} ${puzzle.square_footage}`;
            default:
                return '';
        }
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected and 6 screenshots from the ${this.guess_item.toLowerCase()} are presented to you one at a time. Try to guess the price of the ${this.guess_item.toLowerCase()}.`,
                    `You'll be presented with 5 hints to help you guess the price of the ${this.guess_item.toLowerCase()}. Guess the price within ${
                        this.tolerance_percentage
                    }% to win.`,
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    `Enter what you think the price of the ${this.guess_item.toLowerCase()} is and click submit.`,
                    `If you are within ${this.tolerance_percentage}% of the actual price, you win!`,
                    `All 6 pictures are from the same ${this.guess_item.toLowerCase()}.`,
                    `If you get it wrong, we will reveal an additional screenshot of the ${this.guess_item.toLowerCase()} with an additional hint.`,
                    `You get ${this.max_guesses} guesses in total.`,
                    '⬆️⬆️ means your guess is more than 50% too low',
                    `⬆️ means your guess is too low but within 50%`,
                    `⬇️️⬇️ means your guess is more than 50% too high`,
                    `⬇️️ means your guess is too high but within 50%`,
                ],
            },
        },
    };
}
